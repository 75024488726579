import React from 'react'
import Layout from '../../components/layouts/courseLandingLayout'
import Navbar from '../../components/navbars/courseLandingNavbar';
import { ReactTypeformEmbed } from 'react-typeform-embed';

const Apply = ({location}) => {
  return (
    <Layout>
      <Navbar location={location}/>
      <ReactTypeformEmbed  url='https://projectshift.typeform.com/to/UFqidgr8' />;
    </Layout>
  )
}

export default Apply
